.sidebarDrawer{
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2)
  }
}

.css-yszrlq-MuiTableCell-root{
  padding: 6px 0px!important;
}

.checkboxContainer{
  width: 20%;
}

.serviceNameContainer{
  width: 80%;
  display: flex;
  margin-left: 5px;
  justify-content: space-between;
}

.plannerCrewMembers {
  padding-bottom: 20px;  
}

.plannerDetails{
  margin-bottom: 20px;
  padding-bottom: 50px;
}

.propertyDetailsHeader{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background-color: rgb(246, 255, 243);
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
}

.plannerPropertyDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 30px;
  /* margin: 20px;     */

}

.sidebarCloseButton{
  top: 10px;
  display: flex;
  justify-content: end;
  cursor: pointer;
  right: 10px;
}

.closeButton{
  cursor: pointer;
  right: 14px;
  top: 10px;
}

.serviceTitle {
  margin-bottom: 5px;
}

.subTitlePlanner{
  margin-left: 10px;
}

.individualServiceContainer {
  background-color: rgb(248, 248, 248);
  padding: 15px 10px;
  border-radius: 10px;
}
.detailsPlannerServiceValue , .detailsPlannerCrewMemberValue{
    font-size: 14px;
    color: rgb(99, 97, 97);
}

.plannerNameContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Plannercolor{
  border-radius: 50px;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}


.detailsPlannerServiceValue {
  width: 50%;
}
.detailsPlannerCrewMemberValue {
  width: 40%;
}

.crewMemberdiv {
    background-color: rgb(248, 248, 248);
    padding: 15px 10px;
    border-radius: 10px;
}

.plannerNameContainer .css-uc4va1-MuiButtonBase-root-MuiCheckbox-root{
  padding: 0!important;
}

.pmpro-no-access{
  filter: blur(5px);
  position: relative;
}

.tableContainer{
  position: relative;
}

.goProButton{
  position: absolute!important;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%);
}

.pmpro-no-access{
  filter: blur(5px);
  position: relative;
}

.tableContainer{
  position: relative;
}

.goProButton{
  position: absolute!important;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%);
}

.plannerDetailsContianer .css-1re60hp-MuiAccordionDetails-root, .plannerDetails .css-15v22id-MuiAccordionDetails-root {
  padding: 8px 0px 0px;
  background-color: white;
}

.plannerDetailsContianer .css-rrn746-MuiChip-label {
  padding-left: 6px;
  font-size: 12px;
  padding-right: 6px;
}