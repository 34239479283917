@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap');
body {
  background-color: #ffffff;
  font-family: "Lato", sans-serif;
}

.breadCrumbs {
  cursor: pointer;
}

input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  }

#chart-container {
  width: 100%; /* Or set a specific width */
  overflow-x: auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timeline .vis-time-axis .grid.vis-odd {
  background: #eee;
}

.vis-panel>.vis-content {
  /* top: 0!important; */
  /* transform: none!important; */
}

/* gray background in weekends, white text color */
.vis-time-axis .vis-grid.vis-saturday,
.vis-time-axis .vis-grid.vis-sunday {
  background: rgb(240, 240, 240);
}
.vis-time-axis .vis-text.vis-saturday,
.vis-time-axis .vis-text.vis-sunday {
  color: #e0e0e0;
}
.vis-item {
  background: #ec2b2b;
}

.vis-item.Rock_Beds, .Rock_Beds  {
  background-color: #575c63;
  border-color: #575c63;
  color: white;
}
.vis-item.Hedging, .Hedging {
  background-color: #0b7000;
  border-color: #0b7000;
  color: white;
}
.vis-item.Trash_Pickup, .Trash_Pickup {
  background-color: #50a7da;
  border-color: #50a7da;
  color: white;
}
.vis-item.Mulching, .vis-item.Mulch_Beds, .Mulch_Beds {
  background-color: #e8d966;
  border-color: #e8d966;
  color: white;
}
.vis-item.StringTrimmer, .vis-item.String_Trimmer,  .String_Trimmer{
  background-color: #88a881;
  border-color: #88a881;
  color: white;
}
.vis-item.Hard_Edging, .vis-item.Hard_Edge, .Hard_Edge {
  background-color: #915c30;
  border-color: #915c30;
  color: white;
}
.vis-item.Cleanup_Blowing, .Cleanup_Blowing {
  background-color: #72b4da;
  border-color: #72b4da;
  color: white;
}
.vis-item.Soft_Edging, .vis-item.Soft_Edge, .Soft_Edge,.vis-item.String_Trimmer, .vis-item.String_Trimmer, .String_Trimmer{
  background-color: #d69253;
  border-color: #d69253;
  color: white;
}
.vis-item.Back_To_Truck {
  background-color: #777777;
  border-color: #505050;
  color: white;
}
.vis-item.Lawn_Mowing,
.vis-item.Turf, .Lawn_Mowing, .Turf{
  background-color: #a1d99b;
  color: white;
  border-color: #a1d99b;
}

.vis-item.Small_Turf,
.vis-item.Small_Turf, .Small_Turf, .Small_Turf{
  background-color: #c7e9c0;
  color: white;
  border-color: #c7e9c0;
}
.vis-item.Large_Turf,
.vis-item.Large_Turf, .Large_Turf, .Large_Turf{
  background-color: #6cb55d;
  color: white;
  border-color: #6cb55d;
}

.vis-item.vis-selected {
  border-color: #3a3a3a !important;
  background-color: #4d4d4d !important;
  color: white;
  z-index: 2;
}

.vis-group-level-unknown-but-gte1 {
  border: 1px solid rgb(253, 253, 253) !important;
  border-bottom: 1px solid rgb(243, 243, 243) !important;
}

.vis-foreground .vis-group,
.vis-labelset .vis-label,
.vis-group-level-unknown-but-gte1 {
  border-bottom: 1px solid rgb(233, 233, 233) !important;
}

.vis-panel.vis-bottom,
.vis-panel.vis-center,
.vis-timeline,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top {
  border: 1px solid rgb(233, 233, 233) !important;
}

.vis-ltr .vis-label.vis-nested-group .vis-inner {
  padding-left: 130px !important;
  margin-right: 20px !important;
}

.vis-time-axis.vis-foreground {
  background: rgb(248, 248, 248) !important;
}

.vis-time-axis .vis-text {
  color: #898989 !important;
}

.vis-network {
  overflow: visible;
}

.vis-item.vis-cluster {
  width: fit-content !important;
}

/* .vis-timeline {
  height: 400px !important;
} */

/* .vis-labelset .vis-label, .vis-foreground .vis-group {
  min-height: 50px!important;
} */

.vis-label .vis-inner {
  height: 50px;
}
.vis-labelset .vis-label {
  display: flex;
  align-items: center;
}

.vis-label.vis-nested-group.vis-group-level-unknown-but-gte1 {
  background: #ffffff !important;
}

.vis-labelset .vis-label .vis-inner {
  align-items: center !important;
  display: flex !important;
}

.vis-ltr .vis-label:not(.vis-nesting-group):not(.vis-group-level-0) {
  display: flex;
  align-items: center;
}

.vis-tooltip {
  background-color: black;
  color: white;
}

.highlighted-path {
  stroke-width: 20px !important; /* Adjust the stroke width for highlighting */
  /* stroke: red!important; */
  fill: none !important; /* Ensure there is no fill */
}

/* Normal path style */
.normal-path {
  /* Your existing styles for normal paths */
  opacity: 0.2;
}

.commonButton {
  padding: 0 15px;
  font-size: 16px;
  height: 36px;
  min-width: 100px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background: rgb(0, 140, 255);
  color: white;
}
.commonButton2 {
  padding: 0 15px;
  font-size: 16px;
  height: 36px;
  min-width: 100px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background: #21452e;
  color: white;
}

.control-panel {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 320px;
  max-height: 320px;
  overflow-y: scroll;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 4px 8px;
  margin: 50px 0px 0px 0px;
  font-size: 10px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}

.control-panel label {
  display: inline-block;
  width: 150px;
}

input {
  margin-left: 20px;
}

.disabledClass{
  pointer-events:none;
}

.activeClass{
  cursor: pointer;
}

.eachCrewMemberContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 15px 0;
}

@media (max-width: 768px) {
  .commonButton {
    padding: 0 8px;
    font-size: 13px;
    height: 28px;
    min-width: 60px;
  }
}