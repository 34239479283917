.propertiesContainer{
    margin-top: 8vh;
    padding: 20px 40px;
}

.tableParcelTitle{
    cursor: pointer;
    :hover{
        color: grey;
    }
}