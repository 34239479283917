.surveyContainer {
  height: 92vh;
  margin-top: 8vh;
  width: 100%;
}

.stepperContianer {
  position: absolute;
  z-index: 2;
  background: white;
  height: 92vh;
  padding: 10px 20px;
}
.stepDetailsContianer {
  position: absolute;
  z-index: 2;
  left: 240px;
  background: white;
  height: 92vh;
}

.StepsFormContainer {
  padding: 10px 20px;
  max-height: 90vh;
  overflow-y: auto;
}

.StepsFormContainerHeading {
  font-size: 20px;
  color: #21452e;
  line-height: 0.2;
}

.parkingCardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(246, 255, 240);
  padding: 3px 5px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.parkingAddress {
  font-size: 10px;
}

.polygonContainer {
  padding: 15px 10px;
  background: rgb(246, 255, 240);
  border-radius: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.serviceContainer {
  background-color: #f8f8f8;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
}

.serviceContainer .MuiSelect-select,
.serviceContainer .MuiInputBase-input {
  font-size: 12px;
}

.serviceName {
  font-size: 20px;
  text-align: center;
}

.serviceSubTitle {
  margin: 5px 0;
  font-size: 13px;
  font-weight: 700;
}
.serviceSubTitle1 {
  margin: 10px 0 5px 0;
  font-size: 15px;
  font-weight: 700;
}

.equipmentDataContainer {
  display: flex;
  gap: 10px;
}

.equipmentDataLeft,
.equipmentDataRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.equipmentDataLeft {
  width: 40%;
}
.equipmentDataRight {
  width: 30%;
}
