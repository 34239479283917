.propertyDetailsWrapper{
    padding: 0px 50px 20px 50px;
}

.propertyDetailsWrapper h2 {
    margin-top: auto;
    text-align: center;
    color: #21452E;
    font-size: 35px;
}

.cardsWrapper{
    display: flex;  
}

.detailsContainer{
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.detailsTitle{
    font-size: 14px;
}

.detailsServiceValue{
    font-size: 13px;
    color: rgb(99, 97, 97);
}