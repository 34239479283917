.hoveredSegmentTitle {
  font-size: 12px;
  font-weight: 700;
  width: 50%;
  color: rgb(78, 78, 78);
}

.hoveredSegmentValue {
  font-size: 12px;
  width: 50%;
}

.hoveredRow {
  display: flex;
  gap: 10px;
  margin: 3px 0;
  justify-content: space-between;
  width: 100%;
}

.mapStylesContainer{
  display: flex;
  flex-direction: column;
  width: 98%;
  gap: 5px;
}

.mapStylesContainer label {
  display: flex;
  justify-content: space-between;
}
