.mainCrewMemberContainer {
  padding: 20px 60px;
  margin-top: 50px;
}
h2,
h1 {
  color: #21452e;
}

.wrapper {
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.summaryHeading{
    text-align: center;
}

.mainCrewMemberHeadWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.summaryAndMapContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.mapContainer {
  width: 50%;
}
.summaryContainer {
  width: 45%;
}
.summaryContainerAll {
  width: 50%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.summaryDetails, .summaryDetailsAll {
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #83978a;
  border-radius: 20px;
  background-color: rgb(248, 255, 248);
}

.dayWise {
  margin-bottom: 40px;
}
.summaryDetails {
  min-height: 54vh;
}
.summaryDetailsAll {
  min-height: 30vh;
}

.equipmentNeeded {
  margin: 25px;
}

.backArrow {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.flexContainer {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  justify-content: space-between;
  width: 100%;
}

.subTitle {
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.equipmentContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.itemTitle {
  font-size: 19px;
  width: 50%;
  color: rgb(94, 92, 92);
}
.equipmentTitle {
  font-size: 24px;
  color: rgb(94, 92, 92);
}

.equipmentContent {
  font-size: 18px;
  color: #21452e;
}

.itemContent {
  font-size: 18px;
  width: 40%;
  color: #21452e;
}
